<template>
  <div id="HeaderResetPassword">
    <div class="header__logo">
      <img src="~@/assets/image/Lock.svg" alt="" />
    </div>
    <div class="header__text">
      <span class="f-w6">{{ title }}</span>
      <p class="f-w3">{{ textTutorial }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderResetPassword",
  props: {
    title: {
      type: String,
      require: true
    },
    textTutorial: {
      type: String,
      require: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_fontFamily.scss";
#HeaderResetPassword {
  .header {
    &__logo {
      padding: 80px 0 30px 0;
      img {
        width: 73px;
        height: 82px;
      }
    }
    &__text {
      span {
        font-size: 18px;
        color: #000000;
        line-height: 1.5;
        margin-bottom: 15px;
        display: block;
      }
      p {
        font-size: 14px;
        line-height: 1.15;
        color: #6e6e6e;
        padding: 0 19px;
        margin-bottom: 35px;
      }
    }
  }
}
@media screen and (min-width: 1200px) {
  #HeaderResetPassword {
    .header {
      &__logo {
        padding: 100px 0 30px 0;
        img {
          width: 88px;
          height: 98px;
        }
      }

      &__text {
        span {
          padding: 0 430px;
          font-size: 20px;
          line-height: 29px;
        }

        p {
          padding: 0 371px;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}
</style>
