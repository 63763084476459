<template>
  <div id="ResetPasswordByEmail">
    <AppContainer :use-background="true">
      <HeaderCommon :use-logo="true">
        <template v-slot:headerButton>
          <ButtonBack />
        </template>
      </HeaderCommon>
      <HeaderResetPassword
        title="パスワードを再設定する"
        text-tutorial="パスワードのリセットをリクエストするには、メールアドレスを入力してください。"
      />
      <div class="content">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form
            ref="resetForm"
            @submit.prevent="handleSubmit(onSubmit)"
            class="form f-w6"
          >
            <ValidationProvider
              name="メールアドレス"
              rules="required|email|min:6"
              v-slot="{ errors }"
            >
              <div class="email text-left">
                <label class="f-w6">メールアドレス</label>
                <input
                  type="text"
                  class="f-w3 text"
                  v-model="email"
                  id="email-register"
                  v-on:blur="handleBlur()"
                />
                <span class="error f-w3">{{ errors[0] }}</span>
              </div>
              <button class="f-w3">
                パスワードを再設定する
              </button>
            </ValidationProvider>
          </form>
        </ValidationObserver>
      </div>
    </AppContainer>
  </div>
</template>

<script>
import HeaderResetPassword from "@/components/HeaderResetPassword";
import AppContainer from "@/views/Common/AppContainer";
import HeaderCommon from "@/views/Common/HeaderCommon";
import ButtonBack from "@/views/Common/ButtonBack";
export default {
  name: "ResetPasswordByEmail",
  components: {
    HeaderResetPassword,
    AppContainer,
    HeaderCommon,
    ButtonBack
  },
  data() {
    return {
      email: ""
    };
  },
  methods: {
    handleBlur() {
      this.email = this.email.trim();
    },
    async onSubmit() {
      this.$root.$refs.loading.start();
      await this.$store
        .dispatch("resetPassword/createToken", { email: this.email })
        .then(() => {
          this.$toast(
            "システムは電子メールを送信します。メールを確認してください",
            "成功",
            "success"
          );
          this.$refs.resetForm.reset();
          this.$root.$refs.loading.finish();
        })
        .catch(error => {
          if (error) {
            this.$root.$refs.loading.finish();
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/common/resetPasswordByEmail.scss";
</style>
